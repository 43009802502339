// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirm-signup-js": () => import("./../../../src/pages/confirm-signup.js" /* webpackChunkName: "component---src-pages-confirm-signup-js" */),
  "component---src-pages-passwordrecovery-js": () => import("./../../../src/pages/passwordrecovery.js" /* webpackChunkName: "component---src-pages-passwordrecovery-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

